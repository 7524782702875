import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import { sSection, s } from '../style'
import Image3D from '../components/Image3D'
import SeaIcon from '../assets/icons/seaIcon'
import AirIcon from '../assets/icons/airIcon'
import RailIcon from '../assets/icons/railIcon'

const content = {
  label: {
    en: 'Meet your Freight Partner',
  },
  title: {
    en: 'Looking for a logistics partner in Europe?',
  },
  desc: {
    en: 'OMIDA SEA & AIR S.A. is a company with Polish capital, European spirit and Global reach. We offer our clients comprehensive logistics solutions in sea, air, rail and road transport. We advise them on customs and insurance matters. Since 2012 we have grown and strengthened our leading position in the logistics market. We currently employ 130 people in 7 offices throughout Poland.',
  },
  sea: {
    en: 'Sea',
  },
  air: {
    en: 'Air',
  },
  rail: {
    en: 'Rail',
  },
  services: {
    en: 'Our services include: FCL & LCL SEA TRANSPORT, AIR TRANSPORT, RAIL TRANSPORT, WAREHOUSING, CUSTOMS BROKERAGE, BREAK BULK TRANSPORT, FLEXITANK SERVICE.',
  },
}

const IndexAbout = () => {
  const query = useStaticQuery(graphql`
    {
      seaFront: file(
        absolutePath: { regex: "/images/transport/sea/front.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: NONE
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      seaBack: file(
        absolutePath: { regex: "/images/transport/sea/back.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      airFront: file(
        absolutePath: { regex: "/images/transport/air/front.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: NONE
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      airBack: file(
        absolutePath: { regex: "/images/transport/air/back.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      railFront: file(
        absolutePath: { regex: "/images/transport/rail/front.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: NONE
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      railBack: file(
        absolutePath: { regex: "/images/transport/rail/back.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)
  const { formOpen, formType, setFormType, setFormOpen } = useFormContext()
  const { lang } = useLangContext()

  return (
    <>
      <section
        id='about'
        css={[sSection, { [s.lg]: { marginTop: '10rem' }, overflow: 'hidden' }]}
      >
        <span className='label'>{content.label[lang]}</span>
        <h1
          className='head'
          dangerouslySetInnerHTML={{ __html: content.title[lang] }}
        ></h1>
        <p className='desc'>{content.desc[lang]}</p>
        <p className='desc'>{content.services[lang]}</p>

        <div css={sGrid}>
          <div css={sGridItem} onClick={() => { setFormOpen(true); setFormType('QUOTE'); }}>
            <Image3D
              front={query.seaFront.childImageSharp}
              back={query.seaBack.childImageSharp}
              styling={{ transform: 'translate(10.5%, -32%)' }}
            />
            <div css={sIconContainer}>
              <SeaIcon css={sIcon} />

              <span css={sText}>{content.sea[lang]}</span>
            </div>
          </div>
          <div css={sGridItem} onClick={() => { setFormOpen(true); setFormType('QUOTE'); }}>
            <Image3D
              front={query.airFront.childImageSharp}
              back={query.airBack.childImageSharp}
              styling={{
                top: '0%',
                left: '50%',
                width: '125%',
                transform: 'translate(-50%, -25%)',
              }}
            />
            <div css={sIconContainer}>
              <AirIcon css={sIcon} />
              <span css={sText}>{content.air[lang]}</span>
            </div>
          </div>
          <div css={sGridItem} onClick={() => { setFormOpen(true); setFormType('QUOTE'); }}>
            <Image3D
              front={query.railFront.childImageSharp}
              back={query.railBack.childImageSharp}
              styling={{
                top: 'auto',
                left: 0,
                bottom: 0,
                width: '100%',
                transform: 'translate(-28%, 11%)',
              }}
            />
            <div css={sIconContainer}>
              <RailIcon css={sIcon} />
              <span css={sText}>{content.rail[lang]}</span>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

const sGrid = {
  marginTop: '8rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '5rem',
  [s.sm_down]: {
    marginTop: '4rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    // width: '100%',
    // overflow: 'hidden',
  },
}

const sGridItem = {
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
}

const sIconContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '2rem',
}

const sIcon = {
  width: 32,
  height: 32,
}

const sText = {
  fontWeight: 700,
  marginLeft: '1rem',
  fontSize: '1rem',
  letterSpacing: '0.25em',
  textTransform: 'uppercase',
}

export default IndexAbout
