import React from 'react'
import RegionSections from '../components/RegionSections'
import { useLangContext } from '../context/lang.context'
import { sSection } from '../style'

const content = {
  label: {
    en: 'Enterprise-level global scale',
  },
  title: {
    en: '<br/>Manage and maintain the supply chain coming to Poland.',
  },
  desc: {
    en: '',
  },
}

const IndexRegions = () => {
  const { lang } = useLangContext()
  return (
    <>
      <section id='regions' css={sSection}>
        <h2 className='head'>
          <span className='label'>{content.label[lang]}</span>
          <span dangerouslySetInnerHTML={{ __html: content.title[lang] }} />
        </h2>
        <p className='desc'>{content.desc[lang]}</p>
        <RegionSections />
      </section>
    </>
  )
}

export default IndexRegions
