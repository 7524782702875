import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

const alt = ' | Freight Partner in Poland | Omida Sea And Air S.A.'

const Image3D = ({ name, front, back, styling }) => {
  return (
    <div
      css={{
        position: 'relative',
        top: 0,
        left: 0,
      }}
    >
      <GatsbyImage image={getImage(back)} alt={name + alt} css={sImageBack} />
      <GatsbyImage
        image={getImage(front)}
        alt={name + alt}
        css={[sImageFront, styling]}
      />
    </div>
  )
}

const sImageBack = {
  borderRadius: '2rem',
  img: {
    borderRadius: '2rem',
    objectPosition: 'top',
  },
}

const sImageFront = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 2,
}

export default Image3D
