import React from 'react'
import RegionSection from './RegionSection'
import flags from '../assets/flags'

import { useLangContext } from '../context/lang.context'
import { graphql, useStaticQuery } from 'gatsby'

const RegionSections = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      na: file(absolutePath: { regex: "/images/regions/na/map_na.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      la: file(absolutePath: { regex: "/images/regions/la/map_la.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      me: file(absolutePath: { regex: "/images/regions/me/map_me.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      as: file(absolutePath: { regex: "/images/regions/as/map_as.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      eu: file(absolutePath: { regex: "/images/regions/eu/map_eu.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      au: file(absolutePath: { regex: "/images/regions/au/map_au.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  return (
    <div css={sRegions}>
      {regions.map((region, id) => (
        <RegionSection
          title={region.title[lang]}
          key={region.link}
          link={region.link}
          desc={region.desc[lang]}
          flags={region.flags.map((flag) => flags[flag])}
          flags2={region.flags.map((flag) => flags[flag])}
          // flags={region.flags.map((flag) => flags[flag])}
          // flags2={[flags.poland, flags.ue]}
          image={query[`${region.image}`]?.childImageSharp}
          icons={region.icons}
          last={id === regions.length - 1}
        />
      ))}
    </div>
  )
}

const regions = [
  {
    image: 'na',
    title: {
      en: 'North America',
    },
    desc: {
      en: 'We offer our services to all sea ports in North America such as: Norfolk, Montreal, New York, Chicago, Los Angeles, Veracruz and Altamira for sea shipments.',
    },
    icons: ['sea', 'air'],
    flags: ['sea', 'air'],
    // flags: ['usa', 'canada', 'mexico'],
    link: '/🇺🇸🇨🇦🇲🇽/#read-more',
  },
  {
    image: 'as',
    title: {
      en: 'Asia',
    },
    desc: {
      en: 'Our services are aimed at companies importing all kinds of goods from Asia. The service includes: importing goods from China, warehousing, logistics processes and cooperation with customs agencies.',
    },
    icons: ['sea', 'air', 'rail', 'truck'],
    flags: ['sea', 'air', 'rail', 'truck'],
    // flags: ['china', 'india', 'korea'],
    link: '/asia/#read-more',
  },
  {
    image: 'me',
    title: {
      en: 'Middle East',
    },
    desc: {
      en: 'We offer our services for sea and air shipments all over the Middle East. We guarantee our total dedication and high standards of work.',
    },
    icons: ['sea', 'air'],
    // flags: ['israel', 'saudi', 'emirates'],
    flags: ['sea', 'air'],
    link: '/middle-east/#read-more',
  },
  // {
  //   image: 'la',
  //   title: {
  //     en: 'Latin America',
  //   },
  //   desc: {
  //     en: 'Sed porttitor lectus nibh. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Vivamus suscipit tortor eget felis porttitor volutpat.',
  //   },
  //   icons: ['sea'],
  //   flags: ['mexico', 'brazil'],
  //   link: '/latin-america/',
  // },
  {
    image: 'au',
    title: {
      en: 'Australia',
    },
    desc: {
      en: 'We offer our services for sea and air shipments from all ports in Australia and New Zealand. Thanks to our knowledge of the market and long-term contracts, we offer a competitive level of air and sea freight rates.',
    },
    icons: ['sea', 'air'],
    // flags: ['australia', 'newzeland'],
    flags: ['sea', 'air'],
    link: '/🇦🇺🇳🇿/#read-more',
  },
  {
    image: 'eu',
    title: {
      en: 'Europe',
    },
    desc: {
      en: 'We offer our services for sea and air shipments from all ports in Europe. Based on experience we guarantee perfectly selected transport solutions, tailored to the needs and expectations of the client. Omida Group invests strongly in eco-friendly intermodal transports throughout Europe.',
    },
    icons: ['rail', 'truck'],
    // flags: ['ue', 'ukraine'],
    flags: ['air', 'rail', 'truck'],
    link: '/🇪🇺🇺🇦/#read-more',
  },
]

const sRegions = {
  display: 'flex',
  flexDirection: 'column',
}

export default RegionSections
