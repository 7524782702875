import { Link } from 'gatsby'
import React from 'react'
import Features from '../components/Features'
import { useFormContext } from '../context/form.context'
import { useLangContext } from '../context/lang.context'
import {  alpha, colors, s, sButton, sOuter, sSection } from '../style'

const content = {
  label: {
    en: 'Transshipment in Poland ',
  },
  title: {
    en: '<br/>Seize the opportunity to<br/> expand into European markets!',
  },
  desc: {
    en: 'We provide a professional transportation service. Our sales team prepare a competitive offer, forwarders coordinate the transport process, customs agents efficiently clear the goods, and our carriers deliver them safely on time.',
  },
  link: {
    en: 'Contact us'
  }
}

const features = [
  {
    icon: 'approve',
    title: {
      en: 'Complex Services',
    },
    desc: {
      en: 'We provide you with full logistic and forwarding services within all branches of transport.',
    },
  },
  {
    icon: 'approve',
    title: {
      en: 'Global Reach',
    },
    desc: {
      en: 'You benefit from our global reach which includes 4 continents.',
    },
  },
  {
    icon: 'approve',
    title: {
      en: 'Legal Support',
    },
    desc: {
      en: 'We advise you on customs law.',
    },
  },
  {
    icon: 'approve',
    title: {
      en: 'Process Optimization',
    },
    desc: {
      en: 'You save time thanks to simplified customs clearance.',
    },
  },
  {
    icon: 'approve',
    title: {
      en: 'Cost Reduction',
    },
    desc: {
      en: 'We help you optimise your transport costs.',
    },
  },
  {
    icon: 'approve',
    title: {
      en: 'Quality Guaranteed',
    },
    desc: {
      en: 'You gain peace of mind by entrusting your cargo to specialists with years of experience.',
    },
  },
]

const IndexFeatures = () => {
  const { lang } = useLangContext()
  const { formOpen, formType, setFormType, setFormOpen } = useFormContext()

  return (
    <>
      <section id='features' css={sSection}>
        <h2 className='head'>
          <span className='label'>{content.label[lang]}</span>
          <span dangerouslySetInnerHTML={{ __html: content.title[lang] }} />
        </h2>
        <p className='desc'>{content.desc[lang]}</p>
        <Features data={features} />
        <div css={sButtonContainer}>
          <button
            onClick={() => { setFormOpen(true); setFormType('MESSAGE'); }}
            css={[sButton, sButton2, sButtonModal]}
          >
            {content.link[lang]}
          </button>
        </div>
      </section>
    </>
  )
}

export default IndexFeatures

const sButtonContainer = {
  marginTop: '4rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [s.xs]: {
    marginTop: '2rem',
  },
}

const sButton2 = {
  backgroundColor: colors.brand.concat(alpha[30]),
  '&:hover': {
    backgroundColor: colors.brand,
  },
}

const sButtonModal = {
  color: '#ffffff',
}