import React, { useEffect, useState } from 'react'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import { useLangContext } from '../context/lang.context'
import IndexAbout from '../sections/IndexAbout'
import IndexFeatures from '../sections/IndexFeatures'
import IndexStats from '../sections/IndexStats'
import IndexRegions from '../sections/IndexRegions'
import IndexCompany from '../sections/IndexCompany'
import IndexContact from '../sections/IndexContact'
import FixedBackground from '../components/FixedBackground'
// import PrivacyPopup from '../components/PrivacyPopup'
import FormPopup from '../components/FormPopup'

const seo = {
  title: {
    en: 'Global Transport Connector',
  },
  desc: {
    en: 'Need transportation or logistics partner in Europe? Meet your Freight Partner in Poland. Manage and maintain the supply chain for goods coming to Poland.',
  },
}

const IndexPage = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo title={seo.title[lang]} desc={seo.desc[lang]} isContact />
      <FixedBackground />
      <Intro />
      <IndexAbout />
      <IndexRegions />
      <IndexFeatures />
      <IndexStats />
      <IndexCompany />
      <IndexContact />
      <Footer />
      <FormPopup />
      {/* <PrivacyPopup /> */}
      <Menu />
    </>
  )
}

export default IndexPage
