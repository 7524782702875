import React from 'react'
import { useLangContext } from '../context/lang.context'
import { s, sGridColumns, sSection } from '../style'
import BannerCompany from '../components/BannerCompany'

const content = {
  label: {
    en: 'Sea & Air Transport Experts ',
  },
  title: {
    en: '<br/>Omida Sea And Air',
  },
  desc: {
    en: [
      'We are a company with Polish capital, European spirit and Global reach. Since 2012, we have been growing and strengthening our leading position on the TSL services market. Our great experience in TSL transport translates into the quality of transport services by sea, land or air.',
      'We offer a wide range of TSL services, ensuring their quality at the highest level. Our success is created by people devoted to their work - creative, committed and positive - supporting clients with professional knowledge and experience.',
    ],
  },
}

const IndexCompany = () => {
  const { lang } = useLangContext()

  return (
    <>
      <section
        id='company'
        css={[
          sSection,
          {
            marginBottom: 0,
            paddingBottom: 0,
            [s.sm_down]: { marginBottom: 0, paddingBottom: 0 },
          },
        ]}
      >
        <h2 className='head'>
          <span className='label'>{content.label[lang]}</span>
          <span dangerouslySetInnerHTML={{ __html: content.title[lang] }} />
        </h2>
        <div css={sGridColumns}>
          {content.desc[lang]?.map((text, id) => (
            <p
              key={id}
              className='desc smaller'
              css={{ marginBottom: '0 !important' }}
            >
              {text}
            </p>
          ))}
        </div>
      </section>
      <BannerCompany />
    </>
  )
}

export default IndexCompany
